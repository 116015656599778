import React from 'react';
import './Home.css';

import { Helmet } from 'react-helmet';

import Hero from '../components/Hero'
import Products from '../components/Products'
//import HeroBottom from '../components/HeroBottom'
import Benefits from '../components/Benefits'
import Form from '../components/Form'
import Addon from '../components/Addon'
import Videos from '../components/Videos'
import Registrator from '../components/Registrator'
import Testimonial from '../components/Testimonial'
import Projects from '../components/Projects'

import iconBasket from '../img/icon-basket-colored.svg';
import iconUser from '../img/icon-user-colored.svg';
import iconDelivery from '../img/icon-delivery-colored.svg';
import iconBox from '../img/icon-box-colored.svg';

import img1 from '../img/icon-3g.png';
import img2 from '../img/icon-gps.png';
import img3 from '../img/icon-wifi.png';

import icon1 from '../img/icon-1.png';
import icon2 from '../img/icon-2.png';
import icon3 from '../img/icon-3.png';
import icon4 from '../img/icon-4.png';

import bottom_background from '../img/bottom-background.png';
import monitor1 from '../img/monitor-1.png';
import monitor2 from '../img/monitor-2.png';




import { useTranslation, setLanguage } from 'react-multi-lang';


const Home = (props) => {
	
    const t = useTranslation();

    return (
    <div>
        <Helmet>
            <title>{props.title}</title>
			<meta name="description" content={props.description} />
        </Helmet>
        <Hero/>

        <Products/>

        <div className="container mt-50 mb-50">
            <h2 className="text-center text-bolder">{t('home.home.home1')}</h2>
            <div className="row">
                <a className="col-lg-4 col-md-4 col-xs-12 horizontal-text-with-image" href="https://nscar.online/" target="_blank" rel="nofollow">
                    <img src={img1}/>
                    <span>{t('home.home.home2')}</span>
                </a>
                <a className="col-lg-4 col-md-4 col-xs-12 horizontal-text-with-image" href="https://nscar.online/" target="_blank" rel="nofollow">
                    <img src={img2}/>
                    <span>{t('home.home.home3')}</span>
                </a>
                <a className="col-lg-4 col-md-4 col-xs-12 horizontal-text-with-image" href="https://nscar.online/" target="_blank" rel="nofollow">
                    <img src={img3}/>
                    <span>{t('home.home.home4')}</span>
                </a>
            </div>
        </div>

        <div className="container">
            <h2 className="text-center text-bolder">{t('home.home.home5')}</h2>
            <Registrator/>
        </div>

        <Benefits/>

        <Videos/>


        <section>
            <div className="container">
                <h2 className="text-bolder">{t('home.home.home6')}</h2>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="horizontal-text-with-image bg-light-blue border-20 p-20">
                            <img style={{height:40, width:18}} src={icon1}/>
                            <span>{t('home.home.home7')}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="horizontal-text-with-image bg-light-blue border-20 p-20">
                            <img style={{height:40, width:28}} src={icon2}/>
                            <span>{t('home.home.home8')}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="horizontal-text-with-image bg-light-blue border-20 p-20">
                            <img style={{height:40, width:28}} src={icon3}/>
                            <span>{t('home.home.home9')}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-xs-12">
                        <div className="horizontal-text-with-image bg-light-blue border-20 p-20">
                            <img style={{height:40, width:30}} src={icon4}/>
                            <span>{t('home.home.home10')}</span>
                        </div>
                    </div>
                </div>
                <Form/>
            </div>
        </section>

        <Testimonial/>


        <section style={{backgroundImage: "url(" + bottom_background + ")", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 p-20">
                        <img src={monitor1} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 p-20 align-center">
                        <h3 className="text-bolder text-size-medium bg-white">{t('home.home.home11')}</h3>
                        <p className="text-16 bg-white">{t('home.home.home12')}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 p-20 align-center">
                        <h3 className="text-bolder text-size-medium bg-white">{t('home.home.home13')}</h3>
                        <p className="text-16 bg-white">{t('home.home.home14')}</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        <img src={monitor2} />
                    </div>
                </div>
            </div>
        </section>

        {/*<HeroBottom/>*/}

        <section>
            <div className="container">
                <h2 className="text-bolder">{t('home.home.home15')}</h2>
                <div className="row">
                    <p className="col-xs-12 col-sm-8 col-sm-offset-2 text-center">
                       {t('home.home.home16')}
                    </p>
                </div>
                <Projects/>
            </div>
        </section>

    </div>
    )
};

export default Home