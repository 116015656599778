import React from 'react'
import { Helmet } from 'react-helmet';
import Socials from '../components/Socials'
import Breadcrumbs from '../components/Breadcrumbs';

import { useTranslation, getLanguage } from 'react-multi-lang';

const Opt = (props) => {
    const t = useTranslation()

    return (
    <section className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs4')}</li></ul>
        <Helmet>
            <title>{props.title}</title>
			<meta name="description" content={props.description} />
        </Helmet>
        <div className="container text">
            <h1 className="text-center mb-5">{t('home.opt.opt1')}</h1>

            <p>{t('home.opt.opt2')}</p>
            <p>{t('home.opt.opt3')}</p>

            {/*<h2 className="text-bolder">{t('home.opt.opt4')}</h2>*/}
            <iframe src={(getLanguage() === 'ru') ? 'https://b24-d7co8x.bitrix24.site/crm_form_c5zce' : 'https://b24-d7co8x.bitrix24.site/crm_form_afgxj/'} style={{
                width: '100%',
                height: '610px',
                border: 'none'
            }}/>


            <div className="center-block">
                <div className="hr-text"><div className="w-separator-h"><h4>{t('home.opt.opt5')}</h4></div></div>
                <a className="text-bolder text-size-medium" href="tel:87172760582">8(717) 276-05-82</a><br></br>
                <a className="text-bolder text-size-medium" href="mailto:info@nscar.kz">info@nscar.kz</a><br></br>
                <Socials whatsapp="79195202166" viber="pa?chatURI=nscar" telegram="+79195202166"/>
            </div>

        </div>
    </section>
    )
};

export default Opt