// import logo from './logo.svg';

import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {
    Routes,
    Route
} from 'react-router-dom'
import './App.css';
import './libs/flexgid.min.css'

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Company from "./components/Company";
import Support from "./components/Support";
import Delivery from "./components/Delivery";
import Opt from "./components/Opt";
import Privacy from "./components/Privacy";
import About from "./components/About";
import Guarantee from "./components/Guarantee";
import Contacts from "./components/Contacts";
import CookieConsent from "react-cookie-consent";

import { useTranslation, setLanguage } from 'react-multi-lang';

const close = () => {
    let burger = document.getElementById('hamburger'), humMenu = document.getElementById('hamburger-menu');
    burger.classList.toggle('is-active');
    humMenu.classList.toggle('active');
    let header__logo = document.getElementById('header__logo'), header__dashboard_btn = document.getElementById('header__dashboard_btn');
    // header__logo.classList.toggle('disabled');
    header__dashboard_btn.classList.toggle('active');
};



function App() {
    const t = useTranslation();

    return (
        <div className="wrapper">
            <div className="maincontent">
                <div id="first-screen">
                    <Header />
                </div>
            </div>
            <main>
                <Routes>
                    <Route path="/" element={<Home title={t('meta.title.home')} description={t('meta.description.home')} />}></Route>
                    <Route path="/opt" element={<Opt title={t('meta.title.opt')} description={t('meta.description.opt')} />}></Route>
                    <Route path="/about" element={<About title={t('meta.title.about')} description={t('meta.description.about')} />}></Route>
                    <Route path="/politika-konfidentsialnosti" element={<Privacy title={t('meta.title.politika')} description={t('meta.description.politika')} />}></Route>
                    <Route path="/support" element={<Support title={t('meta.title.support')} description={t('meta.description.support')} />}></Route>
                    <Route path="/delivery" element={<Delivery title={t('meta.title.delivery')} description={t('meta.description.delivery')}/>} ></Route>
                    <Route path="/guarantee" element={<Guarantee title={t('meta.title.guarantee')} description={t('meta.description.guarantee')} />}></Route>
                    <Route path="/contacts" element={<Contacts title={t('meta.title.contacts')} description={t('meta.description.contacts')} />}></Route>
                </Routes>
                <aside className="navigation-ancors-wrap">
                    <nav className="navigation-ancors" id="top_nav">
                        <div className="container container--navigation-ancors" id="hamburger-menu">
                            <ul className="navigation-ancors__list">
                                <li className="navigation-ancors__item" onClick={close}><Link to="/about">{t('home.header.О компании')}</Link></li>
                                <li className="navigation-ancors__item" onClick={close}><Link to="/delivery">{t('home.header.Доставка/Оплата')}</Link></li>
                                <li className="navigation-ancors__item" onClick={close}><Link to="/opt">{t('home.header.Опт/Прайс')}</Link></li>
                                <li className="navigation-ancors__item" onClick={close}><Link to="/support">{t('home.footer.Инструкции')}/{t('home.header.Техподдержка')}</Link></li>
                                <li className="navigation-ancors__item" onClick={close}><Link to="/contacts">{t('home.header.Контакты')}</Link></li>
                                <li className="navigation-ancors__item contacts" style={{flexDirection:'column',alignItems:'center',justifyContent:'center'}} onClick={close}>
                                    <a className="text-bolder" href="tel:87172760582">8(717) 276-05-82</a>
                                    <a className="text-bolder" href="mailto:info@nscar.kz">info@nscar.kz</a>
                                </li>
                                <li className="navigation-ancors__item"><label data-modal-trigger="" aria-controls="axjnf" className="hero__btn btn-primary pointer">{t('home.header.Получить консультацию')}</label></li>
                            </ul>
                        </div>
                    </nav>
                </aside>
                <CookieConsent
                    location="bottom"
                    buttonText="Принять"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B", fontSize: "14px" }}
                    buttonStyle={{ background: "#5583DE", color: "#ffffff", borderRadius: "15px", fontSize: "15px" }}
                    expires={150}
                >
                    Продолжая просмотр, вы соглашаетесь с <Link className="text-primary" to="/politika-konfidentsialnosti">Политикой конфиденциальности</Link>
                </CookieConsent>
            </main>
            <Footer/>
        </div>
    );
}

export default App;