import React from 'react'
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../components/Breadcrumbs';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Privacy = (props) => {
    const t = useTranslation();

    return (
    <section className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs8')}</li></ul>
            <Helmet>
                    <title>{props.title}</title>
					<meta name="description" content={props.description} />
            </Helmet>
            <div className="container">
                    <h1 className="text-center">{t('home.privacy.privacy1')}</h1>
                    <p>{t('home.privacy.privacy2')}</p>
                    <p>{t('home.privacy.privacy2_1')}</p>
                    <h2>{t('home.privacy.privacy3')}</h2>
                    <p>{t('home.privacy.privacy4')}</p>
                    <p>{t('home.privacy.privacy5')}</p>
                    <p>{t('home.privacy.privacy6')}</p>
                    <p>{t('home.privacy.privacy7')}</p>
                    <p>{t('home.privacy.privacy8')}</p>
                    <p>{t('home.privacy.privacy9')}</p>
                    <p>{t('home.privacy.privacy10')}</p>
                    <h2>{t('home.privacy.privacy11')}</h2>
                    <p>{t('home.privacy.privacy12')}</p>
                    <ul>
                        <li>• {t('home.privacy.privacy13')}</li>
                        <li>• {t('home.privacy.privacy14')}</li>
                        <li>• {t('home.privacy.privacy15')}</li>
                        <li>• {t('home.privacy.privacy16')}
                            <ol>
                                <li>1. {t('home.privacy.privacy17')}</li>
                                <li>2. {t('home.privacy.privacy18')}</li>
                                <li>3. {t('home.privacy.privacy19')}</li>
                                <li>4. {t('home.privacy.privacy20')}</li>
                                <li>5. {t('home.privacy.privacy21')}</li>
                                <li>6. {t('home.privacy.privacy22')}</li>
                            </ol>
                        </li>
                    </ul>
                    <p>{t('home.privacy.privacy23')}</p>
                    <p>{t('home.privacy.privacy24')}</p>
                    <p>{t('home.privacy.privacy25')}</p>
                    <h2>{t('home.privacy.privacy26')}</h2>
                    <p>{t('home.privacy.privacy27')}</p>
                    <ul>
                        <li>• {t('home.privacy.privacy28')}</li>
                        <li>• {t('home.privacy.privacy29')}</li>
                        <li>• {t('home.privacy.privacy30')}</li>
                        <li>• {t('home.privacy.privacy31')}</li>
                        <li>• {t('home.privacy.privacy32')}</li>
                        <li>• {t('home.privacy.privacy33')}</li>
                        <li>• {t('home.privacy.privacy34')}</li>
                        <li>• {t('home.privacy.privacy35')}</li>
                    </ul>
                    <h2>{t('home.privacy.privacy36')}</h2>
                    <p>{t('home.privacy.privacy37')}</p>
                    <p>{t('home.privacy.privacy38')}</p>
                    <p>{t('home.privacy.privacy39')}</p>
                    <p>{t('home.privacy.privacy40')}</p>
                    <ul>
                        <li>• {t('home.privacy.privacy41')}</li>
                        <li>• {t('home.privacy.privacy42')}</li>
                        <li>• {t('home.privacy.privacy43')}</li>
                        <li>• {t('home.privacy.privacy44')}</li>
                        <li>• {t('home.privacy.privacy45')}</li>
                    </ul>
                    <h2>{t('home.privacy.privacy46')}</h2>
                    <p>{t('home.privacy.privacy47')}</p>
                    <h2>{t('home.privacy.privacy48')}</h2>
                    <p>{t('home.privacy.privacy49')}</p>
                    <h2>{t('home.privacy.privacy50')}</h2>
                    <p>{t('home.privacy.privacy51')}</p>
                    <p>{t('home.privacy.privacy52')}</p>
                    <p>{t('home.privacy.privacy53')}</p>
                    <p>{t('home.privacy.privacy54')}</p>
                    <p>{t('home.privacy.privacy55')}</p>
                    <p>{t('home.privacy.privacy56')}</p>
                    <h2>{t('home.privacy.privacy57')}</h2>
                    <p>{t('home.privacy.privacy58')}</p>
                    <h2>{t('home.privacy.privacy59')}</h2>
                    <p>{t('home.privacy.privacy60')}</p>
                    <p>{t('home.privacy.privacy61')}</p>
                    <h2>{t('home.privacy.privacy62')}</h2>
                    <p>{t('home.privacy.privacy63')}</p>
                    <ul>
                        <li>- {t('home.privacy.privacy64')} info@nscar.kz;</li>
                        <li>- {t('home.privacy.privacy65')}</li>
                        <li>- {t('home.privacy.privacy66')} 8(717)276-05-82.</li>
                    </ul>
                    
            </div>
    </section>
    )
};

export default Privacy