import React from 'react'
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../components/Breadcrumbs';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Guarantee = (props) => {
    const t = useTranslation()

    return (
    <section className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs7')}</li></ul>
        <Helmet>
            <title>{props.title}</title>
			<meta name="description" content={props.description} />
        </Helmet>
        <div className="container text">
            <h1 className="text-center">{t('home.guarantee.guarantee1')}</h1>
            <ul className="list-checked-primary">
                <li>{t('home.guarantee.guarantee2')}</li>
                <li>{t('home.guarantee.guarantee3')}</li>
                <li>{t('home.guarantee.guarantee4')}</li>
                <li>{t('home.guarantee.guarantee5')}</li>
                <li>{t('home.guarantee.guarantee6')}</li>
                <li>{t('home.guarantee.guarantee7')}</li>
                <li>{t('home.guarantee.guarantee8')}</li>
                <li>{t('home.guarantee.guarantee9')}</li>
            </ul>
        </div>

    </section>
    )
};

export default Guarantee