import React from 'react'
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../components/Breadcrumbs';

import { useTranslation, setLanguage } from 'react-multi-lang';

const Delivery = (props) => {
    const t = useTranslation()

    return (
    <section className="p-0">
        <ul class="breadcrumbs container"><li><a href="/">{t('home.breadcrumbs.breadcrumbs1')}</a></li><li>/</li><li>{t('home.breadcrumbs.breadcrumbs5')}</li></ul>
        <Helmet>
            <title>{t('home.delivery.delivery0')}</title>
			<meta name="description" content={props.description} />
        </Helmet>
        <div className="container text">
            <h1 className="text-center">{t('home.delivery.delivery0')}</h1>
            <h2>{t('home.delivery.delivery1')}</h2>
            <p>{t('home.delivery.delivery2')}</p>
            <p><b>{t('home.delivery.delivery3')}</b> {t('home.delivery.delivery4')}</p>
            <p><b>{t('home.delivery.delivery5')}</b> {t('home.delivery.delivery6')}</p>
            <p>{t('home.delivery.delivery7')}</p>
            <h2>{t('home.delivery.delivery8')}</h2>
            <p>{t('home.delivery.delivery9')}</p>
            <p><b>{t('home.delivery.delivery10')}</b> {t('home.delivery.delivery11')}<br/>{t('home.delivery.delivery12')}</p>
            <p><b>{t('home.delivery.delivery13')}</b> {t('home.delivery.delivery14')}</p>
        </div>
    </section>
    )
};

export default Delivery